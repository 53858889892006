import React from 'react';
import AppRouter from './routes/AppRouter'; // Adjust the path as necessary
import { AuthProvider } from './pages/Profil/AuthProvider'; // Ensure the path is correct
import { HashRouter as Router} from 'react-router-dom';
function App() {
  return (
    <div>
      <AuthProvider>
        <Router>
          <AppRouter />
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;